import React from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from 'react-router';
import { setCurrentTabIndex } from './merchant-health';

export default function ReportDetailsModal({ onHide, details, show }) {
  const navigate=useNavigate();
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {details + " "}Summary
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
            {" "}
            <h6>Your Business is doing well with</h6>
            {[0, 1, 2, 3, 4, 5].map((item) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="badge-circle"></div>
                  <div className="insights-count">lorem epsum</div>
                </div>
              );
            })}
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
            {" "}
            <h6>Your Business needs to work on</h6>
            {[0, 1, 2, 3, 4, 5].map((item) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="badge-circle"></div>
                  <div className="insights-count">lorem epsum</div>
                </div>
              );
            })}
          </div>
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
            {" "}
            <h6>Your Business Scored lowest on</h6>
            {[0, 1, 2, 3, 4, 5].map((item) => {
              return (
                <div className="d-flex align-items-center">
                  <div className="badge-circle"></div>
                  <div className="insights-count">lorem epsum</div>
                </div>
              );
            })}
          </div>
        </div>
        <hr />
        <div>
          <h6 className="mb-0 mt-2">
            {" "}
            Please link your banking data to obtain access to all insights
          </h6>
          <Button
            variant="link"
            className="merchant-dashboard-link"
            onClick={() => {
              navigate("/merchant-health");
              setCurrentTabIndex(0);
            }}
          >
            Go To Accounting Insights
          </Button>
        </div>
        <div>
          <h6 className="mb-0 mt-2">
            Please wait while we verify your identity, if you haven’t uploaded
            your identity documents click this link
          </h6>

          <Button
            variant="link"
            className="merchant-dashboard-link"
            onClick={() => {
              navigate("/merchant-health");
              setCurrentTabIndex(1);
            }}
          >
            Go To Credit Score
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" className="btn-report-modal">
          Download Full Vision Report
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
