import React, { useState, useEffect } from "react";
import "../../styles/master.css";
import Header from "../header/header";
import SiderBarMenu from "./component/sidebar";

import { useAppSelector } from "../../redux/hooks/hooks";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import ReportDetailsModal from "./ReportDetailsModal";

export const setCurrentTabIndex = (index) => {
  localStorage.setItem("activeTabIndex", index);
};

export const getCurrentTabIndex = () => {
  if (!localStorage.getItem("activeTabIndex")) {
    localStorage.setItem("activeTabIndex", 0);
  }
  return Number(localStorage.getItem("activeTabIndex"));
};

function MerchantDashboard() {
  const { currentCountry } = useAppSelector((state) => state.countryReducer);
  const [showModal,setShowModal]=useState(false);
  const [details, setDetails] = useState();
  const circleProgressStyle = {
    // Customize the root svg element
    root: {
      // height: "100px",
    },
    // Customize the path, i.e. the "completed progress"
    path: {
      // Path color
      stroke: `#006090`,
      // strokeWidth:2,
      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
      strokeLinecap: "butt",
      // Customize transition animation
      transition: "stroke-dashoffset 0.5s ease 0s",
      // Rotate the path
      transform: "rotate(0.25turn)",
      transformOrigin: "center center",
      // strokeWidth: 5,
      // backgroundPadding
    },
    // Customize the circle behind the path, i.e. the "total progress"
    trail: {
      // Trail color
      stroke: "#d6d6d6",
      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
      strokeLinecap: "butt",
      // Rotate the trail
      transform: "rotate(0.25turn)",
      transformOrigin: "center center",
    },
    // Customize the text
    text: {
      // Text color
      fill: "#000000",
      fontWeight:600,
      // Text size
      fontSize: "26px",
    },
    // Customize background - only used when the `background` prop is true
    background: {
      fill: "#3e98c7",
    },
  };

  const displayModal=(details)=>{
    setShowModal(true);
    setDetails(details);
  }

  return (
    <div className="dashboard-panel">
      <Header />
      <div className="dashboard-body bg-change-color">
        <div className="container-fluid merchant-body">
          <div
            style={{ display: "flex", alignItems: "flex-start", width: "100%" }}
          >
            <SiderBarMenu />

            <div className="right-panel-main">
              <h3>
                <i className="fa fa-home" aria-hidden="true"></i>
                Dasboard
              </h3>

              <div className="dashboard-box position-relative card dashboard-card no-padding merchant-dashaboard">
                <div className="review-application p-4">
                  <div className="text-right my-3">
                    {/* <button className="btn btn-secondary">
                      <i className="bi bi-info-circle-fill pr-2"></i>
                      Recheck
                    </button> */}
                  </div>
                  <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md- col-sm-6 col-12">
                      <div className="card">
                        <div class="card-body">
                          <div className="row">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                              <h5>Eligibility</h5>
                              <div className="sub-title">On good ground</div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-center">
                              <CircularProgressbarWithChildren
                                styles={circleProgressStyle}
                                value={66}
                                maxValue={200}
                                text="1299"
                              />
                              <small>Bad</small>
                            </div>
                          </div>
                          <div className="my-3">
                            <div className="d-flex ">
                              <div className="pr-2">
                                <i
                                  className="fa fa-lightbulb-o dashboard-icons"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div>
                                <div className="sub-text">
                                  Your score is stable
                                </div>
                                <div className="duration-text">
                                  Updated yesterday
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="insights-updates">
                            <div className="d-flex align-items-center">
                              <div className="badge-circle"></div>
                              <div className="insights-count">
                                Doing well on 10 insights
                              </div>
                            </div>
                          </div>
                          <div className="text-center mt-3">
                            <button
                              className="btn btn-block btn-outline-primary btn-outline-report"
                              onClick={() => displayModal("Eligibility")}
                            >
                              View your Eligibility insights
                            </button>
                          </div>
                        </div>
                      </div>

                      <div></div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md- col-sm-6 col-12">
                      <div className="card">
                        <div class="card-body">
                          <div className="row">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                              <h5>Risk</h5>
                              <div className="sub-title">
                                1 linked bank account
                              </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12  text-center">
                              <CircularProgressbarWithChildren
                                styles={circleProgressStyle}
                                value={66}
                                maxValue={200}
                                text="1299"
                              />
                              <small>Average</small>
                            </div>
                          </div>
                          <div className="my-3">
                            <div className="d-flex ">
                              <div className="pr-2">
                                <i
                                  className="fa fa-lightbulb-o dashboard-icons"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div>
                                <div className="sub-text">
                                  The link to your account expires in 6 days
                                </div>
                                <div className="duration-text">
                                  Updated yesterday
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="insights-updates">
                            <div className="d-flex align-items-center">
                              <div className="badge-circle"></div>
                              <div className="insights-count">
                                Doing well on 11 insights
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="badge-circle"></div>
                              <div className="insights-count">
                                Let's improve on 2 insights
                              </div>
                            </div>
                          </div>
                          <div className="text-center mt-3">
                            <button
                              className="btn btn-block btn-outline-primary btn-outline-report"
                              onClick={() => displayModal("Risk")}
                            >
                              View your Risk insights
                            </button>
                          </div>
                        </div>
                      </div>

                      <div></div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md- col-sm-6 col-12">
                      <div className="card">
                        <div class="card-body">
                          <div className="row">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                              <h5>Affordability</h5>
                              <div className="sub-title">On good ground</div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-center">
                              <CircularProgressbarWithChildren
                                styles={circleProgressStyle}
                                value={66}
                                maxValue={200}
                                text="1299"
                              />
                              <small>Good</small>
                            </div>
                          </div>
                          <div className="my-3">
                            <div className="d-flex ">
                              <div className="pr-2">
                                <i
                                  className="fa fa-lightbulb-o dashboard-icons"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div>
                                <div className="sub-text">
                                  Your score is stable
                                </div>
                                <div className="duration-text">
                                  Updated yesterday
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="insights-updates">
                            <div className="d-flex align-items-center">
                              <div className="badge-circle"></div>
                              <div className="insights-count">
                                Doing well on 10 insights
                              </div>
                            </div>
                          </div>
                          <div className="text-center mt-3">
                            <button
                              className="btn btn-block btn-outline-primary btn-outline-report"
                              onClick={() => displayModal("Affordability")}
                            >
                              View your Affordability insights
                            </button>
                          </div>
                        </div>

                        <div></div>
                        <ReportDetailsModal
                          show={showModal}
                          onHide={() => setShowModal(false)}
                          details={details}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantDashboard;
